<template>
	<span>
		<consent-view
			:id="item.id"
			:start="item.start"
			:project="item.appointment_project"
            :consented="item.consented"
		></consent-view>
	</span>
</template>

<script>
import ConsentView from "@c/consent/ConsentView";
export default {
	name: "ConsentCell",
	props: ["item", "column"],
	components: {
		ConsentView,
	},
};
</script>
